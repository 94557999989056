import React from 'react';
import Button from '@mui/material/Button';
import './footer.css'


function Footer() {
  return (
    <div className='footer'>
        ©Systrarna Lockwall &emsp;&emsp;&emsp;<Button sx={{boxShadow: 1, backgroundColor: "#333232"}}color="secondary" href='https://github.com/anthial'><b>Website by Anthial</b></Button>
    </div>
  );
}

export default Footer;


