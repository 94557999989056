import React from 'react';
import { useEffect, useState } from "react";
import { CircularProgress } from '@mui/material';
import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Header from './Components/header'
import Footer from './Components/footer'
import Home from './Components/pages/home'
const Produktioner = React.lazy(() => import('./Components/pages/produktioner'));
const Bokning = React.lazy(() => import('./Components/pages/bokning'));
const OmOss = React.lazy(() => import('./Components/pages/omoss'));
const Recensioner = React.lazy(() => import('./Components/pages/recensioner'));
const MaBra = React.lazy(() => import('./Components/pages/productions/mabra'));
const Morgon = React.lazy(() => import('./Components/pages/productions/morgon'));
const Stick = React.lazy(() => import('./Components/pages/productions/stickochbrinn'));
const Farligt = React.lazy(() => import('./Components/pages/productions/farligt'));
const Mormor = React.lazy(() => import('./Components/pages/productions/mormor'));
const Prinsen = React.lazy(() => import('./Components/pages/productions/prinsen'));
const Total = React.lazy(() => import('./Components/pages/productions/total'));
const Stick2 = React.lazy(() => import('./Components/pages/productions/stickochbrinn2'));
const SexigTik = React.lazy(() => import('./Components/pages/productions/sexigtik'));
const Albatross = React.lazy(() => import('./Components/pages/productions/albatross'));
const ScrollBack = React.lazy(() => import('./Components/scrollback'));
const Ulla = React.lazy(() => import('./Components/pages/productions/ulla'));
const Me = React.lazy(() => import('./Components/pages/productions/me'));
const Gnolar = React.lazy(() => import('./Components/pages/productions/gnolar'));
const Anita = React.lazy(() => import('./Components/pages/productions/anita'));
const SingSing = React.lazy(() => import('./Components/pages/productions/singsing'));
const JudyLiza = React.lazy(() => import('./Components/pages/productions/judyliza'));
const JLReview = React.lazy(() => import('./Components/pages/productions/jlreview'));
const Glittrande = React.lazy(() => import('./Components/pages/productions/glittrande'));


function App() {
  const produktioner = [{link:"mabra", page: MaBra}, {link:"morgon", page: Morgon}, {link: "stickochbrinn", page: Stick},
  {link: "farligt", page: Farligt}, {link: "mormor", page: Mormor}, {link: "prinsen", page: Prinsen}, 
  {link: "total", page: Total}, {link: "stickochbrinn2", page: Stick2}, {link: "sexigtik", page: SexigTik}, 
  {link: "albatross", page: Albatross}, {link: "ulla", page: Ulla}, {link: "me", page: Me},
  {link: "gnolar", page: Gnolar}, {link: "anita" , page: Anita}, {link: "singsing", page: SingSing}, 
  {link: "judyliza", page: JudyLiza}, {link: "jlreview", page: JLReview}, {link: "glittrande", page: Glittrande}]

  const cardStyle = {
    display: 'block',
    width: 'auto',
    transitionDuration: '0.3s',
    minHeight: '100%',
    maxHeight: "100vh",
    backgroundColor: 'darkgrey'
}

const cardSelectShadow = {
    boxShadow: 2,        
    "&:hover": {
        boxShadow: 20,
        backgroundColor: 'black',
        opacity: 0.8,
    },
  };

const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
      function resizedWindow(){
          setWidth(window.innerWidth);
      }
      window.addEventListener('resize', resizedWindow);
      return () => window.removeEventListener('resize', resizedWindow);
  }, [width])

  function isWide() {
      if (width > 1500) { 
          return true;}
      else { 
          return false; }
  }

  
  
  return (
    <Router>
    <div className='App'>
      <Header/>
      <div className='scrollbar'>
      <React.Suspense fallback={<div><CircularProgress/></div>}>
      <ScrollBack>
      <Routes>
        <Route path="/" element={<Home></Home>}></Route>
        <Route path="/hem" element={<Home></Home>}></Route>
        <Route path="/produktioner" element={<Produktioner cardSelectShadow={cardSelectShadow} cardStyle={cardStyle}></Produktioner>}></Route>
        <Route path="/bokning" element={<Bokning cardSelectShadow={cardSelectShadow} cardStyle={cardStyle}></Bokning>}></Route>
        <Route path="/om" element={<OmOss isWide={isWide()}></OmOss>}></Route>
        <Route path="/recensioner" element={<Recensioner isWide={isWide()}></Recensioner>}></Route>
        {produktioner.map((produktion) => (
          <Route key={produktion.link} path={`/${produktion.link}`}  element={<produktion.page isWide={isWide()}/>}/>
          ))} 
      </Routes>
      </ScrollBack>
      </React.Suspense>
      </div>
      <Footer/>
    </div>
    </Router>

  );
}

export default App;
