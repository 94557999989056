import React from 'react';
import { Link} from 'react-router-dom'
import Button from '@mui/material/Button';
import './header.css'

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider, Icon} from '@mui/material';
import lockLogo from '../images/logo/logga.svg'


const pages = ['Bokning', 'Produktioner', 'Om', 'Recensioner'];

const headerTheme = createTheme({
  palette: {
    primary: {
      light: '#6d6d6d',
      main: '#424242',
      dark: '#1b1b1b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#bdbdbd',
      main: '#efefef',
      dark: '#8d8d8d',
      contrastText: '#000'
    }
  }
})

const Header = () => {

  const openPressTab = () => {
    window.open("https://drive.google.com/drive/folders/1MQh-_kpoYTU-CCG32vuMN6rtIT6IR_A0?usp=sharing", 
              '_blank', 'noopener, noreferrer')
  }

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div className='Header' >
    <ThemeProvider theme={headerTheme}>
    <AppBar position="fixed">
      
    <Toolbar>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <Icon><img alt="Logga för Systrarna Lockwall" src={lockLogo} height={24} width={25}></img></Icon>
          <Typography
            variant="h6"
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'sans-serif',
              fontWeight: 540,
              fontSize: 14,
              letterSpacing: '.08rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Systrarna Lockwall <br/>&amp; Malena Jönsson
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              aria-label="Menu Button"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem key="Hem" onClick={handleCloseNavMenu} component={Link} to={'/'}>
                <Typography sx={{
                    fontFamily: 'sans-serif',
                    color: 'inherit',
                    }} 
                    textAlign="center">Hem</Typography>
              </MenuItem>
              {pages.map((page) => (
                <MenuItem key={page+"2"} onClick={handleCloseNavMenu} component={Link} to={'/'+page.toLocaleLowerCase()+'/'}>
                  <Typography sx={{
                    fontFamily: 'sans-serif',
                    color: 'inherit',
                    }} 
                    textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
              <MenuItem key="Press" onClick={openPressTab} component={Link} to={'/'}>
                <Typography sx={{
                    fontFamily: 'sans-serif',
                    color: 'inherit',
                    }} 
                    textAlign="center">Press</Typography>
              </MenuItem>
            </Menu>
          </Box>
          
          <Typography
            variant="h5"
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'sans-serif',
              fontWeight: 700,
              fontSize: 15,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Systrarna Lockwall &amp; Malena Jönsson
          </Typography>
          
          <Box justifyContent='flex-end' sx={{flexGrow: 1, flexWrap: 'nowrap', display: { xs: 'none', md: 'flex' } }}>
            <Button
                key="Hem"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, fontFamily: 'sans-serif', fontWeight:'200', color: 'white', display: 'block' }}
                component={Link} to={'/'}>
                Hem
              </Button>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, fontFamily: 'sans-serif', fontWeight:'200', color: 'white', display: 'block' }}
                component={Link} to={'/'+page.toLowerCase()+'/'}
              >
                {page}
              </Button>
            ))}
              <Button
                key="Press"
                onClick={handleCloseNavMenu}
                sx={{ my: 2, fontFamily: 'sans-serif', fontWeight:'200', color: 'white', display: 'block' }}
                href="https://drive.google.com/drive/folders/1MQh-_kpoYTU-CCG32vuMN6rtIT6IR_A0?usp=sharing"
              >
                Press
              </Button>
          </Box>
        </Toolbar>
      </Container>
    </Toolbar>
    </AppBar>
    <Toolbar />
    </ThemeProvider>
    </div>
  );
};
export default Header;

