import React from 'react';
import Box from '@mui/material/Box';
import logga from "../../images/logo/logga2.png";
import Grid from '@mui/material/Grid';

import { useEffect, useState } from "react";


import './home.css';

function Home() {
    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        function resizedWindow(){
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', resizedWindow);
        return () => window.removeEventListener('resize', resizedWindow);
    }, [width])

    function isHigherWidth() {
        if (width > 900) { 
            return true;}
        else { 
            return false; }
    }


    return (
    <div >
        <Box sx={{flexgrow: 1}} padding={isHigherWidth() ? '2% 20% 5% 20%' : '5% 5% 5% 5%'}>
            <div className="Home">
            <Grid container rowSpacing={10} columnSpacing={0} justifyContent="center" display={{xs:"none", md: "flex"}}>
            <Box component={Grid} item md={12} display={{xs:"none", md:"block"}}>
                <img src={logga} alt="Systrarna Lockwall och Malena Jönsson, Musikteater." height="160vh" width="auto" style={{objectFit: "contain"}}></img>
            </Box>    
            <Grid item xs={12}>
            <div className="HomeImages">
                <div className={isHigherWidth() ? 'Speed' : 'Slow'}></div>
            </div>
            </Grid>
            </Grid>
            <Grid container rowSpacing={4} columnSpacing={0} justifyContent="center" display={{xs:"flex", md: "none"}}>
            <Grid item xs={12}>
            <div className="HomeImages">
                <div className={isHigherWidth() ? 'Speed' : 'Slow'}></div>
            </div>
            </Grid>
            <Box component={Grid} item xs={12} display={{xs:"block", md:"none"}}>
                <img src={logga} alt="Systrarna Lockwall och Malena Jönsson, Musikteater." height="150vh" width="auto" style={{objectFit: "contain"}}></img>
            </Box>
            </Grid>
            </div>
        </Box>
    </div>
    );
    }

export default Home;


